<template>
    <div v-if="showPage">
        <div class="top"></div>
        <section class="form">
            <div>
                <div class="title">你家宠物是猫还是狗？</div>
                <div class="item">
                    <span
                        class="default"
                        :class="{ active: pet_type == 1 }"
                        @click="changeType(1)"
                        >猫</span
                    >
                    <span
                        class="default"
                        :class="{ active: pet_type == 2 }"
                        style="margin-left: 10px;"
                        @click="changeType(2)"
                        >狗</span
                    >
                </div>
            </div>
            <div style="margin-top: 20px;">
                <div class="title">你家宠物叫什么名字？</div>
                <div class="item">
                    <input
                        v-model="pet_name"
                        maxlength="20"
                        class="item-input"
                        type="text"
                        placeholder="请输入你家宠物的名字"
                    />
                </div>
            </div>
            <div style="margin-top: 20px;">
                <h4 class="title">传一张你家宠物的靓照吧！</h4>
                <div class="item" style="margin-top: -10px;">
                    <mmcUploaderDefault
                        :maxNum="1"
                        style="margin-top: 20px"
                        @changeend="afterRead"

                    />
                </div>
            </div>
        </section>

       

        <section class="tip">
            提交默认同意<span @click="showGuiZe">活动规则</span>
        </section>
        <div class="btn-wrap">
            <div class="pr-btn" @click="submitForm">提交</div>
        </div>
        <div style="height:30px"></div>
        <modal-guize :visible.sync="guiZeVisible"></modal-guize>
    </div>
</template>

<script>
import modalGuize from './guize.vue';
import { authChecker, shareCtrl, tracking } from '@/common/js/mmcModule';
import mmcUploaderDefault from '@/components/mmcUI/mmc-uploader-default';
import api from './api';
import utils from '@/common/js/utils';
import saUtils from 'sensors-data-utils';
var reqObj = utils.getRequestParams();
let pageKey = 'h5_pop_jsb_onepoint_apply';
export default {
    data() {
        return {
            guiZeVisible: false,
            pet_type: 0,
            pet_name: '',
            pet_image: '',
            showPage: false,
            activity_state: 1,
        };
    },
    components: {
        modalGuize,
        mmcUploaderDefault,
    },
    methods: {
        changeType(n) {
            this.pet_type = n;
        },
        showGuiZe() {
            this.guiZeVisible = true;
            saUtils.click('button_apply_rules')
            saUtils.popup('h5_pop_jsb_apply_rules')
        },
        afterRead(file) {
            console.log(file);
            this.pet_image = file[0];
        },
        submitForm() {
        
            // if (this.activity_state == 2) {
            //     alertMsg('活动已开始，不可报名');
            //     return;
            // }
            if (this.activity_state == 3) {
                alertMsg('活动已结束');
                return;
            }
            if (this.pet_type == 0) {
                alertMsg('请选择宠物类型');
                return;
            }
            if (this.pet_name.trim() == '') {
                alertMsg('请输入宠物名字');
                return;
            }
            if (this.pet_name.trim().length > 20) {
                alertMsg('宠物名字不能超过20个字');
                return;
            }
            if (this.pet_image == '') {
                alertMsg('请上传宠物照片');
                return;
            }
            showLoading('提交中');
            api.perSubmit({
                pet_type: this.pet_type,
                pet_name: this.pet_name,
                pet_image: this.pet_image,
            }).then(res => {
                hideLoading()
                if (res.code == 0) {
                    location.href =
                        '/fund/activity/onepoint/applySuccess?id=' +
                        res.data.penny_pet_id;
                }
            });
            saUtils.update({
                pet_type: this.pet_type==1?'猫':'狗',
                name: this.pet_name,
                pet_image: this.pet_image,
            })
            saUtils.click('button_submit')
        },
    },
    created() {
        authChecker.check({ notneedphone: 1 }, () => {
            showLoading('加载中...');
            api.perCheck().then(res => {
                hideLoading();
                if (res.code == 0) {
                    this.activity_state = res.data.activity_state;
                    if(reqObj.ismock){
                        this.activity_state = 1;
                        res.data.apply = 0;
                    }
                    
                    if (res.data.apply) {
                        location.href =
                            '/fund/activity/onepoint/applySuccess?id=' +
                            res.data.penny_pet_id;
                    } else {
                        this.showPage = true;
                    }
                }
            });

            shareCtrl.init({
                title: '抢129元卫仕猫粮/狗粮',
                imgUrl: `https://m.jisuchou.net/fund/onepoint-share.jpg`,
                desc: '邀请50位好友，每人助力1分钱',
                url: `https://m.jisuchou.net/fund/activity/onepoint/apply`,
                titleMoment: '抢129元卫仕猫粮/狗粮',
                shareRecord: {
                    scene: pageKey,
                },
            });
        });

        tracking.init();

        saUtils.init(pageKey);

        saUtils.pv();
    },
};
</script>

<style lang="less" scoped>
.top {
    background: url(./bg-onepoint.png) no-repeat top center;
    background-size: contain;
    height: 100vw;
}
.form {
    border-radius: 8px;
    border: 1px solid #333;
    margin: 0 16px;
    padding: 20px;
    margin-top: -21vw;
    text-align: left;

    .title {
        font-size: 16px;
        color: #333;
        font-weight: 500;
    }
    .item {
        margin-top: 10px;
        .default {
            width: 80px;
            height: 35px;
            font-size: 14px;
            color: #333;
            background-color: #f3f3f3;
            border-radius: 4px;
            display: inline-block;
            text-align: center;
            line-height: 35px;
        }
        .active {
            background-color: #6dc53a;
            color: #fff;
        }
    }
    .item-input {
        border: none;
        background-color: #f3f3f3;
        padding: 12px;
        font-size: 14px;
        color: #333;
        width: 100%;
        border-radius: 5px;
    }
}

.btn-wrap {
    // display: flex;
    // // text-align: center;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;

    margin-top: 10px;

    .pr-btn {
        padding: 15px 30px;
        // width: 255px;
        // background-image: linear-gradient(to right, #6DC53A, #e91919);
        background-color: #6dc838;

        border-radius: 25px;
        line-height: 1em;
        font-size: 18px;
        color: #fff;
        min-width: 130px;
        font-weight: 600;
        margin: 0px 16px 24px;
    }
}
.tip {
    margin-top: 15px;
    color: #333;
    text-align: center;
    font-size: 12px;
    span {
        color: #6dc53a;
        text-decoration: underline;
    }
}
.item /deep/ .btn-up{
    border: none;
    background-color: #f3f3f3;
}
</style>
