import { mmcAjax } from '@/common/js/mmcModule';

function perCheck() {
    return mmcAjax({
        method: 'get',
        url: `${window.APIBasePath}mmc/penny/apply/check`,
        crossDomain: true,
    })
}
export function perSubmit(data) {

    return mmcAjax({
        method: 'post',
        url: `${window.APIBasePath}mmc/penny/apply/submit`,
        data,
        crossDomain: true,
    })
}

export default {
    perCheck,
    perSubmit
}